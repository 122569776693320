import React from "react"
import { graphql} from "gatsby"
import * as PropTypes from "prop-types"

import {
  Breadcrum, LastUpdated, FinderField, FinderResults, HowItWorks, SEO
} from "../../components/index"
import * as styles from './tokenFinder.module.scss'
import { baseTokenType, baseSetType, imgSrcType } from '../../types'

class TokenFinderPage extends React.PureComponent {
  constructor(props) {
    super(props);
    let localData = {};
    try {
      const localDataString = localStorage.getItem("tokenFinderData")
      localData = localDataString ? JSON.parse(localDataString) : {};
    } catch (e) {
      localData = {}
    }
    this.state = {
      searchData: localData
    };
  }

  breadcrumPages = [
    {
      text: 'Token finder',
      active: true,
      to: '/token-finder/'
    }
  ];

  onHandleFormSubmit = (data) => {
    this.setState({
      searchData: data
    })
    localStorage.setItem("tokenFinderData", JSON.stringify(data));
  };
  
  onHandleBackClick = () => {
    this.setState({
      searchData: {}
    })
    localStorage.removeItem("tokenFinderData");
  }

  render () {
    const { tokenList } = this.props.data;
    const { searchData } = this.state;
    const showFinderField = Object.keys(searchData).length === 0;
    return (
      <>
        <SEO
          title="Find Tokens for your MTG deck"
          description="A quick way to find all the tokens you need for your latest Magic: the Gathering (MTG) deck. Just copy and paste your deck list to get started..."
          keywords={[`finder`, `token finder`,  `search`, `database`, 'deck', 'EDH', 'cube']}
          pageUrl="/token-finder/"
        />
        <Breadcrum pages={this.breadcrumPages} />
        <h1>Token Finder</h1>
        <h2>Find MTG Tokens for your deck</h2>
        <p>A quick way to find all the Magic: the Gathering token cards you need for your latest deck.  Works great for Commander / Elder Dragon Highlander (EDH) decks or a MTG Cube. Just paste a deck list to view all the tokens your deck can create.</p>
        <LastUpdated />
        <section className={styles.wrapper}>
          <div className={styles.container}>
            { 
              showFinderField && <FinderField onHandleFormSubmit={(data) => this.onHandleFormSubmit(data)} />
            }
            {
              !showFinderField && <FinderResults searchData={searchData} tokens={tokenList.edges} handleBackClick={() => this.onHandleBackClick()} />
            }
          </div>
          {
            showFinderField && <HowItWorks />
          }
        </section>
      </>
    )
  }
}

TokenFinderPage.propTypes = {
  data: PropTypes.shape({
    tokenList: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseTokenType,
          sources: PropTypes.arrayOf(PropTypes.string),
          tokens: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            imgSrc: imgSrcType,
            set: PropTypes.shape({
              ...baseSetType,
            })
          }))
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}

export const tokenFinderQuery = graphql`
  query TokenFinderQuery {
    tokenList: allTypesJson(sort: {fields: [name], order: ASC}) {
      totalCount
      edges {
        node {
          id
          name
          power
          toughness
          color
          sources
          tokens {
            id
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 250) {
                  base64
                  base64
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            set {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default TokenFinderPage
